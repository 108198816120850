import React from "react";

type ExportIconProps = {
  black?: boolean;
};

export const ExportIcon: React.FC<ExportIconProps> = ({ black }) => {
  const color = black ? "black" : "white";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 18 18" fill="none">
      <path
        d="M3 12L3 12.75C3 13.9926 4.00736 15 5.25 15L12.75 15C13.9926 15 15 13.9926 15 12.75L15 12M12 9L9 12M9 12L6 9M9 12L9 3"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
