type PasteIconProps = {
  className?: string;
};

export const PasteIcon: React.FC<PasteIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <rect x="0.326172" y="0.786133" width="16" height="16" rx="2" fill="#0E0E0E" />
      <path
        d="M9.66124 10.0839V11.5255L12.6775 8.62096L9.66124 5.70581V7.15807C9.65986 7.26345 9.61739 7.36413 9.54286 7.43866C9.46834 7.51318 9.36765 7.55566 9.26227 7.55704C8.74627 7.55704 4.55441 7.67407 3.89478 11.4297C5.42471 10.2381 7.32407 9.62063 9.26227 9.68489C9.36765 9.68626 9.46834 9.72874 9.54286 9.80326C9.61739 9.87779 9.65986 9.97847 9.66124 10.0839Z"
        fill="white"
      />
    </svg>
  );
};
