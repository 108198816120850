type ThumbDownIconProps = {
  size?: "small" | "medium" | "large";
  fill?: string;
  stroke?: string;
};

export const ThumbDownIcon: React.FC<ThumbDownIconProps> = ({ size, fill, stroke }) => {
  if (size === "large") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
        <path
          d="M13.3333 12.8333L17.7003 12.8333C19.0631 12.8333 19.9495 11.3991 19.3401 10.1801L16.1317 3.76344C15.8212 3.14234 15.1864 2.75 14.4919 2.75L10.809 2.75C10.6591 2.75 10.5098 2.76838 10.3644 2.80474L6.91667 3.66667M13.3333 12.8333L13.3333 17.4167C13.3333 18.4292 12.5125 19.25 11.5 19.25L11.4125 19.25C10.9546 19.25 10.5833 18.8788 10.5833 18.4209C10.5833 17.7661 10.3895 17.1259 10.0263 16.5811L7.52778 12.8333M13.3333 12.8333L11.5 12.8333M6.91667 3.66667L6.91667 11.9167L7.52778 12.8333M6.91667 3.66667L5.08333 3.66667C4.07081 3.66667 3.25 4.48748 3.25 5.5L3.25 11C3.25 12.0125 4.07081 12.8333 5.08333 12.8333L7.52778 12.8333"
          stroke="#DD524C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            d="M9.16667 12.8333L4.79973 12.8333C3.43686 12.8333 2.55045 11.3991 3.15995 10.1801L6.36828 3.76344C6.67883 3.14234 7.31365 2.75 8.00806 2.75L11.691 2.75C11.8409 2.75 11.9902 2.76838 12.1356 2.80474L15.5833 3.66667L15.5833 11.9167L14.9722 12.8333L12.4737 16.5811C12.1105 17.1259 11.9167 17.7661 11.9167 18.4209C11.9167 18.8788 11.5454 19.25 11.0875 19.25H11C9.98748 19.25 9.16667 18.4292 9.16667 17.4167L9.16667 12.8333Z"
            fill={fill || "#BABABA"}/>
        <path
            d="M15.5833 3.66667L15.5833 11.9167L14.9722 12.8333H17.4167C18.4292 12.8333 19.25 12.0125 19.25 11L19.25 5.5C19.25 4.48748 18.4292 3.66667 17.4167 3.66667H15.5833Z"
            fill={fill || "#BABABA"}/>
        <path
            d="M9.16667 12.8333L4.79973 12.8333C3.43686 12.8333 2.55045 11.3991 3.15995 10.1801L6.36828 3.76344C6.67883 3.14234 7.31365 2.75 8.00806 2.75L11.691 2.75C11.8409 2.75 11.9902 2.76838 12.1356 2.80474L15.5833 3.66667M9.16667 12.8333L9.16667 17.4167C9.16667 18.4292 9.98748 19.25 11 19.25H11.0875C11.5454 19.25 11.9167 18.8788 11.9167 18.4209C11.9167 17.7661 12.1105 17.1259 12.4737 16.5811L14.9722 12.8333M9.16667 12.8333L11 12.8333M15.5833 3.66667L15.5833 11.9167L14.9722 12.8333M15.5833 3.66667H17.4167C18.4292 3.66667 19.25 4.48748 19.25 5.5L19.25 11C19.25 12.0125 18.4292 12.8333 17.4167 12.8333H14.9722"
            stroke={stroke || "#BABABA"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
