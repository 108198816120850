export const NbaBallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 1C5.03754 1 1 5.03754 1 10C1 14.9625 5.03754 19 10 19C14.9625 19 19 14.9625 19 10C19 5.03754 14.9625 1 10 1ZM9.22115 2.42823C9.25092 2.42546 9.27792 2.43031 9.30769 2.42823V9.30769H7.18715C7.04789 7.41975 6.31345 5.62461 5.08946 4.18046C6.25764 3.19161 7.6983 2.58039 9.22115 2.42754V2.42823ZM10.6923 2.42823C12.2459 2.56191 13.7198 3.17383 14.9112 4.17977C13.6866 5.62393 12.9517 7.41935 12.8122 9.30769H10.6923V2.42823ZM4.11538 5.17462C5.08289 6.35 5.67138 7.79105 5.80323 9.30769H2.42685C2.56027 7.79151 3.14885 6.35107 4.11538 5.17531V5.17462ZM15.8846 5.17462C16.851 6.35065 17.4394 7.79135 17.5725 9.30769H14.1975C14.3293 7.79137 14.9175 6.35059 15.8846 5.17531V5.17462ZM2.42754 10.6923H5.80254C5.67075 12.2086 5.08251 13.6494 4.11538 14.8247C3.1491 13.6488 2.56076 12.2084 2.42754 10.6923ZM7.18715 10.6923H9.30769V17.5725C7.75398 17.4384 6.28003 16.826 5.08877 15.8195C6.31301 14.3755 7.0477 12.5803 7.18715 10.6923ZM10.6923 10.6923H12.8128C12.9521 12.5803 13.6865 14.3754 14.9105 15.8195C13.7195 16.8258 12.2458 17.4382 10.6923 17.5725V10.6923ZM14.1975 10.6923H17.5725C17.4392 12.2084 16.8509 13.6488 15.8846 14.8247C14.9172 13.6495 14.3295 12.2087 14.1975 10.6923Z"
        fill="white"
      />
    </svg>
  );
};
