type CloseIconProps = {
  size?: "small" | "medium" | "large";
  color?: string;
};

export const CloseIcon: React.FC<CloseIconProps> = ({ size, color }) => {
  size = size || "medium";
  color = color || "#000";

  switch (size) {
    case "small":
      return (
        <svg
          className="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      );
    case "medium":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
          <path
            d="M10.5 31.5L31.5 10.5M10.5 10.5L31.5 31.5"
            stroke={color}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "large":
      return (
        <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round">
          <path d="M18 6L6 18M6 6l12 12"></path>
        </svg>
      );
  }
};
