export const SignOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      data-testid="sign-out-icon"
    >
      <path
        d="M14.1667 13.3335L17.5 10.0002M17.5 10.0002L14.1667 6.66683M17.5 10.0002L5.83333 10.0002M10.8333 13.3335V14.1668C10.8333 15.5475 9.71405 16.6668 8.33333 16.6668H5C3.61929 16.6668 2.5 15.5475 2.5 14.1668V5.8335C2.5 4.45278 3.61929 3.3335 5 3.3335H8.33333C9.71405 3.3335 10.8333 4.45278 10.8333 5.8335V6.66683"
        stroke="#DD524C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
