import { SVGProps } from "react";

type DropIconProps = SVGProps<SVGSVGElement> & {
  red?: boolean;
};

export const DropIcon: React.FC<DropIconProps> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" fill="none" {...props}>
      <path d="M5 14L14 5M5 5L14 14" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
