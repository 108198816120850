export const VercelIcon = () => {
  return (
    <svg
      width={22}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M37.5274 0L75.0548 65H0L37.5274 0Z" fill="#000000" />
    </svg>
  );
};