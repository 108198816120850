export const SendIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.2395" cy="16.2395" r="16.2395" />
      <path
        d="M17.5562 7.02246C17.0714 6.53766 16.2854 6.53766 15.8006 7.02246L7.90028 14.9228C7.41548 15.4076 7.41548 16.1936 7.90028 16.6784C8.38508 17.1632 9.1711 17.1632 9.6559 16.6784L16.6784 9.6559L23.7009 16.6784C24.1857 17.1632 24.9717 17.1632 25.4565 16.6784C25.9413 16.1936 25.9413 15.4076 25.4565 14.9228L17.5562 7.02246ZM17.9198 25.4565L17.9198 7.90028L15.437 7.90028L15.437 25.4565L17.9198 25.4565Z"
        fill="white"
      />
    </svg>
  );
};
