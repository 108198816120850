type ChatIconProps = {
  white?: boolean;
  className?: string;
};

export const ChatIcon: React.FC<ChatIconProps> = ({ white, className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4629 23.5961H5.00667C4.63445 23.5961 4.29241 23.3877 4.12139 23.0439C3.95037 22.7001 3.96043 22.2937 4.17169 21.9707L6.35471 18.574V10.1135C6.34465 7.51911 8.38684 5.40399 10.8817 5.40399H19.4629C21.9679 5.40399 24 7.50869 24 10.1031V18.8866C24 21.481 21.9679 23.5857 19.4629 23.5857V23.5961ZM6.87784 21.5122H19.4629C20.8512 21.5122 21.988 20.3349 21.988 18.897V10.1135C21.988 8.67566 20.8512 7.49827 19.4629 7.49827H10.8817C9.49344 7.49827 8.35666 8.67566 8.35666 10.1135V18.897C8.35666 19.1054 8.2963 19.3034 8.18564 19.4701L6.87784 21.5122Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M19.1108 15.4586C19.8664 15.4586 20.479 14.8242 20.479 14.0416C20.479 13.259 19.8664 12.6246 19.1108 12.6246C18.3552 12.6246 17.7426 13.259 17.7426 14.0416C17.7426 14.8242 18.3552 15.4586 19.1108 15.4586Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M15.1773 15.4586C15.9329 15.4586 16.5454 14.8242 16.5454 14.0416C16.5454 13.259 15.9329 12.6246 15.1773 12.6246C14.4217 12.6246 13.8091 13.259 13.8091 14.0416C13.8091 14.8242 14.4217 15.4586 15.1773 15.4586Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M11.244 15.4586C11.9996 15.4586 12.6121 14.8242 12.6121 14.0416C12.6121 13.259 11.9996 12.6246 11.244 12.6246C10.4883 12.6246 9.87579 13.259 9.87579 14.0416C9.87579 14.8242 10.4883 15.4586 11.244 15.4586Z"
        fill={white ? "white" : "black"}
      />
    </svg>
  );
};
