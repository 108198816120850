type ThumbUpIconProps = {
  size?: "small" | "medium" | "large";
  fill?: string;
  stroke?: string;
};

export const ThumbUpIcon: React.FC<ThumbUpIconProps> = ({ size, fill , stroke }) => {
  if (size === "large") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
        <path
          d="M13.3333 9.66667L17.7003 9.66667C19.0631 9.66667 19.9495 11.1009 19.3401 12.3199L16.1317 18.7366C15.8212 19.3577 15.1864 19.75 14.4919 19.75L10.809 19.75C10.6591 19.75 10.5098 19.7316 10.3644 19.6953L6.91667 18.8333M13.3333 9.66667L13.3333 5.08333C13.3333 4.07081 12.5125 3.25 11.5 3.25L11.4125 3.25C10.9546 3.25 10.5833 3.62122 10.5833 4.07915C10.5833 4.73393 10.3895 5.37406 10.0263 5.91886L7.52778 9.66667M13.3333 9.66667L11.5 9.66667M6.91667 18.8333L6.91667 10.5833L7.52778 9.66667M6.91667 18.8333L5.08333 18.8333C4.07081 18.8333 3.25 18.0125 3.25 17L3.25 11.5C3.25 10.4875 4.07081 9.66667 5.08333 9.66667L7.52778 9.66667"
          stroke="#46C07E"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M12.8335 9.16667L17.2004 9.16667C18.5633 9.16667 19.4497 10.6009 18.8402 11.8199L15.6319 18.2366C15.3213 18.8577 14.6865 19.25 13.9921 19.25H10.3092C10.1593 19.25 10.01 19.2316 9.86454 19.1953L6.41683 18.3333L6.41683 10.0833L9.52648 5.41887C9.88968 4.87406 10.0835 4.23393 10.0835 3.57915C10.0835 3.12122 10.4547 2.75 10.9126 2.75H11.0002C12.0127 2.75 12.8335 3.57081 12.8335 4.58333L12.8335 9.16667Z"
        fill={fill || "#BABABA"}
      />
      <path
        d="M6.41683 18.3333L6.41683 10.0833L7.02794 9.16667H4.5835C3.57098 9.16667 2.75016 9.98748 2.75016 11V16.5C2.75016 17.5125 3.57098 18.3333 4.5835 18.3333H6.41683Z"
        fill={fill || "#BABABA"}
      />
      <path
        d="M12.8335 9.16667L17.2004 9.16667C18.5633 9.16667 19.4497 10.6009 18.8402 11.8199L15.6319 18.2366C15.3213 18.8577 14.6865 19.25 13.9921 19.25H10.3092C10.1593 19.25 10.01 19.2316 9.86454 19.1953L6.41683 18.3333M12.8335 9.16667L12.8335 4.58333C12.8335 3.57081 12.0127 2.75 11.0002 2.75H10.9126C10.4547 2.75 10.0835 3.12122 10.0835 3.57915C10.0835 4.23393 9.88968 4.87406 9.52648 5.41887L6.41683 10.0833M12.8335 9.16667H11.0002M6.41683 18.3333L6.41683 10.0833M6.41683 18.3333H4.5835C3.57098 18.3333 2.75016 17.5125 2.75016 16.5V11C2.75016 9.98748 3.57098 9.16667 4.5835 9.16667H7.02794L6.41683 10.0833"
        stroke={stroke || "#BABABA"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
