import { SVGProps } from "react";

export const LampIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 229.018 229.018"
      xmlSpace="preserve"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          d="M114.512,51.743c-32.669,0-59.248,26.579-59.248,59.248c0,13.37,4.384,26.003,12.672,36.527
		c1.636,2.083,3.355,4.077,5.175,6.188l0.1,0.116c5.568,6.46,11.325,13.142,13.475,21.01c0.486,1.803,0.555,5.26,0.527,7.923v4.526
		c0,4.142,3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5v-4.579c-0.025-3.478,0.155-6.016,0.518-7.333
		c2.162-7.816,8.542-15.321,14.222-22.001l0.245-0.288c1.639-1.926,3.188-3.745,4.598-5.538c8.3-10.53,12.687-23.169,12.687-36.551
		C173.754,78.322,147.178,51.743,114.512,51.743z M149.281,138.264c-1.235,1.571-2.691,3.282-4.233,5.093l-0.302,0.355
		c-6.635,7.804-14.155,16.649-17.203,27.667c-0.431,1.564-0.91,3.904-1.032,8.402h-24.312c-0.104-4.777-0.591-7.227-1.036-8.877
		c-3.05-11.164-10.557-19.875-16.59-26.876l-0.101-0.118c-1.705-1.977-3.315-3.845-4.746-5.665
		c-6.19-7.86-9.462-17.285-9.462-27.254c0-24.398,19.85-44.248,44.248-44.248c24.395,0,44.242,19.85,44.242,44.248
		C158.754,120.971,155.48,130.399,149.281,138.264z"
        />
        <path
          d="M133.984,196.91H94.713c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h39.272c4.142,0,7.5-3.358,7.5-7.5
		S138.126,196.91,133.984,196.91z"
        />
        <path
          d="M124.166,214.018h-19.635c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h19.635c4.142,0,7.5-3.358,7.5-7.5
		C131.666,217.376,128.308,214.018,124.166,214.018z"
        />
        <path
          d="M218,103.491h-25.873c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H218c4.142,0,7.5-3.358,7.5-7.5
		C225.5,106.849,222.142,103.491,218,103.491z"
        />
        <path
          d="M44.394,110.991c0-4.142-3.358-7.5-7.5-7.5H11.018c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h25.876
		C41.036,118.491,44.394,115.133,44.394,110.991z"
        />
        <path
          d="M114.51,40.881c4.142,0,7.5-3.358,7.5-7.5V7.5c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v25.881
		C107.01,37.523,110.368,40.881,114.51,40.881z"
        />
        <path
          d="M174.693,160.569c-2.929-2.929-7.677-2.93-10.607-0.001c-2.929,2.929-2.93,7.677-0.001,10.607l18.296,18.301
		c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.838-0.732,5.303-2.196c2.929-2.929,2.93-7.677,0.001-10.607L174.693,160.569z"
        />
        <path
          d="M54.317,61.408c1.465,1.465,3.384,2.198,5.304,2.198c1.919,0,3.838-0.732,5.302-2.196c2.93-2.929,2.93-7.677,0.002-10.606
		L46.636,32.508c-2.929-2.93-7.677-2.93-10.606-0.002c-2.93,2.929-2.93,7.677-0.002,10.606L54.317,61.408z"
        />
        <path
          d="M54.325,160.569L36.028,178.87c-2.929,2.929-2.928,7.678,0.001,10.606c1.464,1.464,3.384,2.196,5.303,2.196
		c1.919,0,3.839-0.732,5.304-2.197l18.297-18.301c2.929-2.929,2.928-7.678-0.001-10.606C62.003,157.64,57.254,157.64,54.325,160.569
		z"
        />
        <path
          d="M169.393,63.605c1.919,0,3.839-0.732,5.304-2.197l18.292-18.295c2.929-2.929,2.928-7.678-0.001-10.606
		c-2.929-2.929-7.678-2.929-10.606,0.001l-18.292,18.295c-2.929,2.929-2.928,7.678,0.001,10.606
		C165.554,62.874,167.473,63.605,169.393,63.605z"
        />
      </g>
    </svg>
  );
};
