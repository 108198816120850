export const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8333 5.83333L15.1105 15.9521C15.0482 16.8243 14.3225 17.5 13.4481 17.5H6.55178C5.67739 17.5 4.95165 16.8243 4.88935 15.9521L4.16659 5.83333M8.33325 9.16667V14.1667M11.6666 9.16667V14.1667M12.4999 5.83333V3.33333C12.4999 2.8731 12.1268 2.5 11.6666 2.5H8.33325C7.87301 2.5 7.49992 2.8731 7.49992 3.33333V5.83333M3.33325 5.83333H16.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
