type ProfileIconProps = {
  size?: "small" | "medium" | "large";
};

export const ProfileIcon: React.FC<ProfileIconProps> = ({ size }) => {
  if (size === "large") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M21.3332 9.33333C21.3332 12.2789 18.9454 14.6667 15.9998 14.6667C13.0543 14.6667 10.6665 12.2789 10.6665 9.33333C10.6665 6.38781 13.0543 4 15.9998 4C18.9454 4 21.3332 6.38781 21.3332 9.33333Z"
          stroke="#0E0E0E"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9998 18.6667C10.8452 18.6667 6.6665 22.8453 6.6665 28H25.3332C25.3332 22.8453 21.1545 18.6667 15.9998 18.6667Z"
          stroke="#0E0E0E"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.3334 5.83333C13.3334 7.67428 11.841 9.16667 10 9.16667C8.15907 9.16667 6.66669 7.67428 6.66669 5.83333C6.66669 3.99238 8.15907 2.5 10 2.5C11.841 2.5 13.3334 3.99238 13.3334 5.83333Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.6667C6.77836 11.6667 4.16669 14.2783 4.16669 17.5H15.8334C15.8334 14.2783 13.2217 11.6667 10 11.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
