export const FanDuelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.2591 5.30724C10.6448 4.47759 11.0872 3.6934 11.5977 2.96603C11.8019 2.67054 12.0174 2.39778 12.233 2.13638C12.267 2.07956 12.233 2 12.1649 2C12.1082 2 12.0515 2 11.9947 2C9.71457 2 7.51381 2.38641 5.47187 3.09105C4.41687 3.45473 3.3959 3.90933 2.4203 4.44349C2.14804 4.59124 1.98923 4.87536 2.00057 5.18222C2.02326 6.31873 2.14804 7.42114 2.36358 8.50082C2.55643 9.51231 2.82869 10.5011 3.16901 11.4557C3.5774 12.6036 4.08789 13.706 4.70047 14.7402C5.81219 16.6609 7.23021 18.3771 8.89779 19.8204C9.18139 20.0705 9.47634 20.3091 9.77129 20.5364C9.83935 20.5819 9.93011 20.5137 9.89607 20.4455C9.77129 20.1387 9.65785 19.8204 9.55575 19.5022C9.04527 17.9679 8.72763 16.32 8.62553 14.6152C8.62553 14.5357 8.5915 14.4675 8.55747 14.4107C8.13774 13.831 7.76338 13.2401 7.42306 12.6036C7.72935 12.4331 8.05833 12.2854 8.38731 12.1604C8.53478 12.1036 8.62553 11.9672 8.63688 11.8081C8.71629 10.6716 8.88645 9.54641 9.15871 8.47809C9.17005 8.42127 9.12467 8.36444 9.06795 8.37581C8.03564 8.61447 7.03736 8.96679 6.11848 9.43276C5.85757 8.54628 5.66472 7.63708 5.55128 6.70514C6.93526 6.11416 8.42134 5.71638 9.96414 5.52317C10.0776 5.52317 10.191 5.43225 10.2591 5.30724Z"
        fill="#1493FF"
      />
      <path
        d="M21.5689 4.44338C20.5933 3.90923 19.5836 3.46599 18.5173 3.09094C17.0993 2.60224 15.6245 2.27266 14.0817 2.11354C13.957 2.10218 13.8435 2.14764 13.7528 2.2272C12.9246 3.07958 12.1873 4.1138 11.586 5.29577C11.5634 5.35259 11.5974 5.40942 11.6541 5.40942C11.7675 5.40942 11.8696 5.40942 11.9831 5.40942C14.2746 5.40942 16.464 5.87538 18.4606 6.7164C18.3471 7.64834 18.1543 8.56891 17.8934 9.44402C17.5644 10.5464 17.122 11.6034 16.5775 12.6035C15.443 14.6947 13.8662 16.5245 11.9831 17.9451C11.3251 17.445 10.7125 16.9109 10.1453 16.3199C10.0886 16.2631 9.99787 16.3085 10.0092 16.3881C10.1567 17.2518 10.3495 18.0928 10.5991 18.8884C10.9508 20.0022 11.4045 21.025 11.9491 21.957C11.9717 21.9911 12.0171 22.0138 12.0625 21.9911C13.1402 21.3546 14.1612 20.6273 15.1027 19.809C16.759 18.3656 18.1883 16.6495 19.3 14.7288C19.9013 13.6832 20.4118 12.5921 20.8315 11.4443C21.1718 10.4896 21.4441 9.50084 21.6369 8.48935C21.8411 7.40967 21.9659 6.29589 21.9999 5.17075C21.9999 4.86389 21.8411 4.57977 21.5689 4.44338Z"
        fill="#1493FF"
      />
    </svg>
  );
};
