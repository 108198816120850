type SwitchIconProps = {
  size?: "small" | "medium" | "large";
};

export const SwitchIcon: React.FC<SwitchIconProps> = ({ size }) => {
  if (size === "large")
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path
          d="M9.33332 18.6666H6.99999C5.71133 18.6666 4.66666 17.622 4.66666 16.3333V6.99996C4.66666 5.7113 5.71133 4.66663 6.99999 4.66663H16.3333C17.622 4.66663 18.6667 5.7113 18.6667 6.99996V9.33329M11.6667 23.3333H21C22.2887 23.3333 23.3333 22.2886 23.3333 21V11.6666C23.3333 10.378 22.2887 9.33329 21 9.33329H11.6667C10.378 9.33329 9.33332 10.378 9.33332 11.6666V21C9.33332 22.2886 10.378 23.3333 11.6667 23.3333Z"
          stroke="#0E0E0E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 16H6C4.89543 16 4 15.1046 4 14V6C4 4.89543 4.89543 4 6 4H14C15.1046 4 16 4.89543 16 6V8M10 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8H10C8.89543 8 8 8.89543 8 10V18C8 19.1046 8.89543 20 10 20Z"
        stroke="#0E0E0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
