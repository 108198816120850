export const NflBallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.1656 2.08784C8.85918 2.30945 6.40718 3.00146 4.70478 4.7039C3.00078 6.40793 2.30958 8.85918 2.08798 11.1656M11.1656 2.08784C12.5016 1.95984 13.7896 1.98784 14.8552 2.06944C15.6528 2.12402 16.4037 2.46554 16.969 3.03087C17.5343 3.59619 17.8758 4.34708 17.9304 5.1447C18.029 6.37289 18.0228 7.60724 17.912 8.83438M11.1656 2.08784L17.912 8.83438M2.08798 11.1656C1.97718 12.3928 1.97102 13.6271 2.06958 14.8553C2.12416 15.6529 2.46568 16.4038 3.03099 16.9691C3.5963 17.5345 4.34718 17.876 5.14478 17.9306C6.21038 18.0122 7.49838 18.0402 8.83438 17.9122M2.08798 11.1656L8.83438 17.9122M17.912 8.83438C17.6904 11.1408 16.9984 13.5929 15.296 15.2953C13.592 16.9993 11.1408 17.6906 8.83438 17.9122M12.648 7.35195L7.35198 12.6481M7.35198 10.3784L9.62158 12.6481M10.3784 7.35195L12.648 9.62159"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
