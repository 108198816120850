export const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.28033 9.77513C5.98744 9.48223 5.51256 9.48223 5.21967 9.77513C4.92678 10.068 4.92678 10.5429 5.21967 10.8358L7.94202 13.5581C8.23492 13.851 8.70979 13.851 9.00268 13.5581L15.447 7.11383C15.7399 6.82093 15.7399 6.34606 15.447 6.05317C15.1541 5.76027 14.6792 5.76027 14.3863 6.05317L8.47235 11.9672L6.28033 9.77513Z"
        fill="#005FDB"
      />
    </svg>
  );
};
