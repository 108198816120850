export const HamburgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
      <path
        d="M6.3335 9.5H31.6668M6.3335 19H31.6668M6.3335 28.5H31.6668"
        stroke="#0E0E0E"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
