import React, { SVGProps } from "react";

export const LockIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M9.5 11.75V13.25M5 16.25H14C14.8284 16.25 15.5 15.5784 15.5 14.75V10.25C15.5 9.42157 14.8284 8.75 14 8.75H5C4.17157 8.75 3.5 9.42157 3.5 10.25V14.75C3.5 15.5784 4.17157 16.25 5 16.25ZM12.5 8.75V5.75C12.5 4.09315 11.1569 2.75 9.5 2.75C7.84315 2.75 6.5 4.09315 6.5 5.75V8.75H12.5Z"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};
