export const DfsProIcon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <path d="M26.0033 8.46191C27.3906 8.46191 28.5095 9.5821 28.5095 10.9617C28.5095 12.3414 27.3865 13.4616 26.0033 13.4616C24.6201 13.4616 23.4971 12.3414 23.4971 10.9617C23.4971 9.5821 24.6201 8.46191 26.0033 8.46191Z" />
      <path d="M20.1528 12.889C21.2177 12.889 22.081 12.0279 22.081 10.9657C22.081 9.90355 21.2177 9.04248 20.1528 9.04248C19.0879 9.04248 18.2246 9.90355 18.2246 10.9657C18.2246 12.0279 19.0879 12.889 20.1528 12.889Z" />
      <path d="M22.6139 31.5C23.4804 31.5 24.1829 30.7994 24.1829 29.9351C24.1829 29.0708 23.4804 28.3701 22.6139 28.3701C21.7474 28.3701 21.0449 29.0708 21.0449 29.9351C21.0449 30.7994 21.7474 31.5 22.6139 31.5Z" />
      <path d="M22.6227 7.62993C23.4892 7.62993 24.1917 6.92927 24.1917 6.06496C24.1917 5.20066 23.4892 4.5 22.6227 4.5C21.7562 4.5 21.0537 5.20066 21.0537 6.06496C21.0537 6.92927 21.7562 7.62993 22.6227 7.62993Z" />
      <path d="M18.8073 30.7952C19.2839 30.7952 19.6702 30.4098 19.6702 29.9345C19.6702 29.4591 19.2839 29.0737 18.8073 29.0737C18.3307 29.0737 17.9443 29.4591 17.9443 29.9345C17.9443 30.4098 18.3307 30.7952 18.8073 30.7952Z" />
      <path d="M16.0402 30.4082C16.3025 30.4082 16.5151 30.1961 16.5151 29.9345C16.5151 29.673 16.3025 29.4609 16.0402 29.4609C15.778 29.4609 15.5654 29.673 15.5654 29.9345C15.5654 30.1961 15.778 30.4082 16.0402 30.4082Z" />
      <path d="M13.889 30.1942C14.0327 30.1942 14.1491 30.078 14.1491 29.9347C14.1491 29.7915 14.0327 29.6753 13.889 29.6753C13.7454 29.6753 13.6289 29.7915 13.6289 29.9347C13.6289 30.078 13.7454 30.1942 13.889 30.1942Z" />
      <path d="M11.9574 30.1942C12.101 30.1942 12.2175 30.078 12.2175 29.9347C12.2175 29.7915 12.101 29.6753 11.9574 29.6753C11.8137 29.6753 11.6973 29.7915 11.6973 29.9347C11.6973 30.078 11.8137 30.1942 11.9574 30.1942Z" />
      <path d="M26.0033 27.5382C27.3875 27.5382 28.5095 26.419 28.5095 25.0384C28.5095 23.6578 27.3875 22.5386 26.0033 22.5386C24.6191 22.5386 23.4971 23.6578 23.4971 25.0384C23.4971 26.419 24.6191 27.5382 26.0033 27.5382Z" />
      <path d="M20.1528 26.9613C21.2177 26.9613 22.081 26.1002 22.081 25.038C22.081 23.9758 21.2177 23.1147 20.1528 23.1147C19.0879 23.1147 18.2246 23.9758 18.2246 25.038C18.2246 26.1002 19.0879 26.9613 20.1528 26.9613Z" />
      <path d="M21.9127 21.7351C23.9696 21.7351 25.637 20.072 25.637 18.0204C25.637 15.9688 23.9696 14.3057 21.9127 14.3057C19.8559 14.3057 18.1885 15.9688 18.1885 18.0204C18.1885 20.072 19.8559 21.7351 21.9127 21.7351Z" />
      <path d="M14.3966 20.4254C15.7283 20.4254 16.8079 19.3486 16.8079 18.0203C16.8079 16.692 15.7283 15.6152 14.3966 15.6152C13.0649 15.6152 11.9854 16.692 11.9854 18.0203C11.9854 19.3486 13.0649 20.4254 14.3966 20.4254Z" />
      <path d="M9.00452 19.5855C9.87104 19.5855 10.5735 18.8848 10.5735 18.0205C10.5735 17.1562 9.87104 16.4556 9.00452 16.4556C8.138 16.4556 7.43555 17.1562 7.43555 18.0205C7.43555 18.8848 8.138 19.5855 9.00452 19.5855Z" />
      <path d="M5.16079 18.8811C5.63737 18.8811 6.02372 18.4958 6.02372 18.0204C6.02372 17.545 5.63737 17.1597 5.16079 17.1597C4.6842 17.1597 4.29785 17.545 4.29785 18.0204C4.29785 18.4958 4.6842 18.8811 5.16079 18.8811Z" />
      <path d="M2.41134 18.4941C2.67358 18.4941 2.88617 18.282 2.88617 18.0205C2.88617 17.7589 2.67358 17.5469 2.41134 17.5469C2.14911 17.5469 1.93652 17.7589 1.93652 18.0205C1.93652 18.282 2.14911 18.4941 2.41134 18.4941Z" />
      <path d="M0.260119 18.2801C0.403779 18.2801 0.520238 18.164 0.520238 18.0207C0.520238 17.8774 0.403779 17.7612 0.260119 17.7612C0.116459 17.7612 0 17.8774 0 18.0207C0 18.164 0.116459 18.2801 0.260119 18.2801Z" />
      <path d="M15.2399 26.6031C16.1064 26.6031 16.8088 25.9024 16.8088 25.0381C16.8088 24.1738 16.1064 23.4731 15.2399 23.4731C14.3734 23.4731 13.6709 24.1738 13.6709 25.0381C13.6709 25.9024 14.3734 26.6031 15.2399 26.6031Z" />
      <path d="M11.4332 25.8987C11.9098 25.8987 12.2962 25.5133 12.2962 25.038C12.2962 24.5626 11.9098 24.1772 11.4332 24.1772C10.9567 24.1772 10.5703 24.5626 10.5703 25.038C10.5703 25.5133 10.9567 25.8987 11.4332 25.8987Z" />
      <path d="M8.66623 25.5117C8.92846 25.5117 9.14105 25.2996 9.14105 25.0381C9.14105 24.7765 8.92846 24.5645 8.66623 24.5645C8.40399 24.5645 8.19141 24.7765 8.19141 25.0381C8.19141 25.2996 8.40399 25.5117 8.66623 25.5117Z" />
      <path d="M6.51988 25.2972C6.66354 25.2972 6.78 25.1811 6.78 25.0378C6.78 24.8945 6.66354 24.7783 6.51988 24.7783C6.37623 24.7783 6.25977 24.8945 6.25977 25.0378C6.25977 25.1811 6.37623 25.2972 6.51988 25.2972Z" />
      <path d="M15.2399 12.5308C16.1064 12.5308 16.8088 11.8302 16.8088 10.9658C16.8088 10.1015 16.1064 9.40088 15.2399 9.40088C14.3734 9.40088 13.6709 10.1015 13.6709 10.9658C13.6709 11.8302 14.3734 12.5308 15.2399 12.5308Z" />
      <path d="M11.4332 11.8264C11.9098 11.8264 12.2962 11.4411 12.2962 10.9657C12.2962 10.4903 11.9098 10.105 11.4332 10.105C10.9567 10.105 10.5703 10.4903 10.5703 10.9657C10.5703 11.4411 10.9567 11.8264 11.4332 11.8264Z" />
      <path d="M8.66623 11.4394C8.92846 11.4394 9.14105 11.2274 9.14105 10.9658C9.14105 10.7042 8.92846 10.4922 8.66623 10.4922C8.40399 10.4922 8.19141 10.7042 8.19141 10.9658C8.19141 11.2274 8.40399 11.4394 8.66623 11.4394Z" />
      <path d="M6.51988 11.2255C6.66354 11.2255 6.78 11.1093 6.78 10.966C6.78 10.8227 6.66354 10.7065 6.51988 10.7065C6.37623 10.7065 6.25977 10.8227 6.25977 10.966C6.25977 11.1093 6.37623 11.2255 6.51988 11.2255Z" />
      <path d="M18.8151 6.92556C19.2917 6.92556 19.678 6.5402 19.678 6.06483C19.678 5.58946 19.2917 5.2041 18.8151 5.2041C18.3385 5.2041 17.9521 5.58946 17.9521 6.06483C17.9521 6.5402 18.3385 6.92556 18.8151 6.92556Z" />
      <path d="M16.0451 6.53852C16.3074 6.53852 16.52 6.32648 16.52 6.06492C16.52 5.80335 16.3074 5.59131 16.0451 5.59131C15.7829 5.59131 15.5703 5.80335 15.5703 6.06492C15.5703 6.32648 15.7829 6.53852 16.0451 6.53852Z" />
      <path d="M13.8978 6.32409C14.0415 6.32409 14.1579 6.20792 14.1579 6.06463C14.1579 5.92134 14.0415 5.80518 13.8978 5.80518C13.7542 5.80518 13.6377 5.92134 13.6377 6.06463C13.6377 6.20792 13.7542 6.32409 13.8978 6.32409Z" />
      <path d="M11.9623 6.32409C12.1059 6.32409 12.2224 6.20792 12.2224 6.06463C12.2224 5.92134 12.1059 5.80518 11.9623 5.80518C11.8186 5.80518 11.7021 5.92134 11.7021 6.06463C11.7021 6.20792 11.8186 6.32409 11.9623 6.32409Z" />
      <path d="M31.5282 22.4808C33.9978 22.4808 35.9998 20.484 35.9998 18.0207C35.9998 15.5574 33.9978 13.5605 31.5282 13.5605C29.0586 13.5605 27.0566 15.5574 27.0566 18.0207C27.0566 20.484 29.0586 22.4808 31.5282 22.4808Z" />
    </svg>
  );
};
