import React from "react";

export const GoogleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6486 10.3687H12.1807V13.8492H17.0182C16.8096 14.9742 16.1768 15.9258 15.2229 16.5633C14.4166 17.1023 13.3877 17.4211 12.1783 17.4211C9.83691 17.4211 7.85645 15.8391 7.14863 13.7133C6.97051 13.1742 6.86738 12.5977 6.86738 12.0047C6.86738 11.4117 6.97051 10.8352 7.14863 10.2961C7.85879 8.17266 9.83926 6.59063 12.1807 6.59063C13.5002 6.59063 14.6838 7.04531 15.6166 7.93594L18.1947 5.35547C16.6361 3.90234 14.6041 3.01172 12.1807 3.01172C8.66738 3.01172 5.62754 5.02734 4.14863 7.96641C3.53926 9.18047 3.19238 10.5539 3.19238 12.007C3.19238 13.4602 3.53926 14.8312 4.14863 16.0453C5.62754 18.9844 8.66738 21 12.1807 21C14.6088 21 16.6432 20.1937 18.1291 18.8203C19.8283 17.2547 20.8104 14.9484 20.8104 12.2086C20.8104 11.5711 20.7541 10.9594 20.6486 10.3687Z"
        fill="white"
      />
    </svg>
  );
};
