import clsx from "clsx";

type ArrowDropdownIconProps = {
  reverse?: boolean;
};

export const ArrowDropdownIcon: React.FC<ArrowDropdownIconProps> = ({ reverse }) => {
  return (
    <svg
      className={clsx(reverse && "rotate-180 transform")}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.47138 5.52851C4.21103 5.26816 3.78892 5.26816 3.52858 5.52851C3.26823 5.78886 3.26823 6.21097 3.52858 6.47132L7.52857 10.4713C7.78892 10.7317 8.21103 10.7317 8.47138 10.4713L12.4714 6.47132C12.7317 6.21097 12.7317 5.78886 12.4714 5.52851C12.211 5.26816 11.7889 5.26816 11.5286 5.52851L7.99998 9.05711L4.47138 5.52851Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};
