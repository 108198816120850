type LineupIconProps = {
  white?: boolean;
  className?: string;
};

export const LineupIcon: React.FC<LineupIconProps> = ({ white, className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1424 23.434C17.5908 23.434 17.1394 22.9665 17.1394 22.3952V17.8451C17.1394 16.7543 16.2768 15.8609 15.2237 15.8609H12.8164C11.7633 15.8609 10.9007 16.7543 10.9007 17.8451V22.3952C10.9007 22.9665 10.4493 23.434 9.89769 23.434C9.34604 23.434 8.89468 22.9665 8.89468 22.3952V17.8451C8.89468 15.6012 10.6499 13.7833 12.8164 13.7833H15.2237C17.3902 13.7833 19.1454 15.6012 19.1454 17.8451V22.3952C19.1454 22.9665 18.6941 23.434 18.1424 23.434Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M14.01 12.5678C12.1444 12.5678 10.6299 10.9992 10.6299 9.06696C10.6299 7.13473 12.1444 5.5661 14.01 5.5661C15.8756 5.5661 17.3902 7.13473 17.3902 9.06696C17.3902 10.9992 15.8756 12.5678 14.01 12.5678ZM14.01 7.64376C13.2477 7.64376 12.6359 8.28784 12.6359 9.06696C12.6359 9.84608 13.2578 10.4902 14.01 10.4902C14.7623 10.4902 15.3841 9.84608 15.3841 9.06696C15.3841 8.28784 14.7623 7.64376 14.01 7.64376Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M5.00301 23.434C4.45135 23.434 4 22.9665 4 22.3952V19.4657C4 17.5023 5.54463 15.9025 7.44032 15.9025C7.99197 15.9025 8.44333 16.3699 8.44333 16.9413C8.44333 17.5127 7.99197 17.9801 7.44032 17.9801C6.64794 17.9801 6.00602 18.645 6.00602 19.4657V22.3952C6.00602 22.9665 5.55466 23.434 5.00301 23.434Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M7.69108 14.9778C6.15648 14.9778 4.91275 13.6897 4.91275 12.1003C4.91275 10.5109 6.15648 9.22272 7.69108 9.22272C9.22569 9.22272 10.4694 10.5109 10.4694 12.1003C10.4694 13.6897 9.22569 14.9778 7.69108 14.9778ZM7.69108 11.3004C7.26982 11.3004 6.91877 11.664 6.91877 12.1003C6.91877 12.5366 7.26982 12.9002 7.69108 12.9002C8.11235 12.9002 8.4634 12.5366 8.4634 12.1003C8.4634 11.664 8.11235 11.3004 7.69108 11.3004Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M22.997 23.434C22.4453 23.434 21.994 22.9665 21.994 22.3952V19.4657C21.994 18.645 21.3521 17.9801 20.5597 17.9801C20.008 17.9801 19.5567 17.5127 19.5567 16.9413C19.5567 16.3699 20.008 15.9025 20.5597 15.9025C22.4554 15.9025 24 17.5023 24 19.4657V22.3952C24 22.9665 23.5486 23.434 22.997 23.434Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M20.3089 14.9778C18.7743 14.9778 17.5306 13.6897 17.5306 12.1003C17.5306 10.5109 18.7743 9.22272 20.3089 9.22272C21.8435 9.22272 23.0872 10.5109 23.0872 12.1003C23.0872 13.6897 21.8435 14.9778 20.3089 14.9778ZM20.3089 11.3004C19.8876 11.3004 19.5366 11.664 19.5366 12.1003C19.5366 12.5366 19.8876 12.9002 20.3089 12.9002C20.7302 12.9002 21.0812 12.5366 21.0812 12.1003C21.0812 11.664 20.7402 11.3004 20.3089 11.3004Z"
        fill={white ? "white" : "black"}
      />
    </svg>
  );
};
