export const RegenerateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 21 20" fill="none">
      <path
        d="M3.83331 3.33337V7.50004H4.31791M17.1151 9.16671C16.705 5.87811 13.8997 3.33337 10.5 3.33337C7.70216 3.33337 5.30689 5.05685 4.31791 7.50004M4.31791 7.50004H7.99998M17.1666 16.6667V12.5H16.682M16.682 12.5C15.6931 14.9432 13.2978 16.6667 10.5 16.6667C7.1003 16.6667 4.29497 14.122 3.88489 10.8334M16.682 12.5H13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
