export const RssIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.18 15.6399C6.75817 15.6399 7.31266 15.8696 7.72149 16.2784C8.13032 16.6873 8.36 17.2418 8.36 17.8199C8.36 18.9999 7.38 19.9999 6.18 19.9999C5 19.9999 4 18.9999 4 17.8199C4 17.2418 4.22968 16.6873 4.63851 16.2784C5.04734 15.8696 5.60183 15.6399 6.18 15.6399ZM4 4.43994C8.12677 4.43994 12.0845 6.07929 15.0026 8.99736C17.9206 11.9154 19.56 15.8732 19.56 19.9999H16.73C16.73 16.6237 15.3888 13.3858 13.0015 10.9985C10.6141 8.61113 7.37621 7.26994 4 7.26994V4.43994ZM4 10.0999C6.62564 10.0999 9.14375 11.143 11.0004 12.9996C12.857 14.8562 13.9 17.3743 13.9 19.9999H11.07C11.07 18.1249 10.3251 16.3266 8.99924 15.0007C7.67336 13.6748 5.87508 12.9299 4 12.9299V10.0999Z"
        fill="white"
      />
    </svg>
  );
};
