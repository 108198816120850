export const SaveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 28 28" fill="none">
      <path
        d="M5.83331 5.83333C5.83331 4.54467 6.87798 3.5 8.16665 3.5H19.8333C21.122 3.5 22.1666 4.54467 22.1666 5.83333V24.5L14 20.4167L5.83331 24.5V5.83333Z"
        stroke="#FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
