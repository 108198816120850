import React from "react";

type CloudTickIconProps = {
  className?: string;
};

export const CloudTickIcon: React.FC<CloudTickIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 0.75C12.8672 0.75 14.2734 1.65967 14.981 2.99561C16.4268 2.55176 18.0615 2.90332 19.2041 4.0459C20.3467 5.18848 20.6982 6.82764 20.2544 8.26904C21.5903 8.97656 22.5 10.3828 22.5 12C22.5 13.6172 21.5903 15.0234 20.2544 15.731C20.6982 17.1768 20.3467 18.8115 19.2041 19.9541C18.0615 21.0967 16.4224 21.4482 14.981 21.0044C14.2734 22.3403 12.8672 23.25 11.25 23.25C9.63281 23.25 8.22656 22.3403 7.51904 21.0044C6.07324 21.4482 4.43848 21.0967 3.2959 19.9541C2.15332 18.8115 1.80176 17.1724 2.24561 15.731C0.909668 15.0234 0 13.6172 0 12C0 10.3828 0.909668 8.97656 2.24561 8.26904C1.80176 6.82324 2.15332 5.18848 3.2959 4.0459C4.43848 2.90332 6.07764 2.55176 7.51904 2.99561C8.22656 1.65967 9.63281 0.75 11.25 0.75ZM16.2158 9.93457C16.6289 9.52148 16.6289 8.85352 16.2158 8.44482C15.8027 8.03613 15.1348 8.03174 14.7261 8.44482L9.84814 13.3228L7.78272 11.2573C7.36963 10.8442 6.70166 10.8442 6.29297 11.2573C5.88428 11.6704 5.87988 12.3384 6.29297 12.7471L9.10547 15.5596C9.51855 15.9727 10.1865 15.9727 10.5952 15.5596L16.2158 9.93457Z"
        fill="#00A3FF"
      />
    </svg>
  );
};
