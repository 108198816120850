export const NbaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <mask id="path-1-inside-1_3118_6833" fill="white">
        <path d="M4 3.5C4 2.11929 5.11929 1 6.5 1H19.5C20.8807 1 22 2.11929 22 3.5V20.5C22 21.8807 20.8807 23 19.5 23H6.5C5.11929 23 4 21.8807 4 20.5V3.5Z" />
      </mask>
      <path
        d="M4 3.5C4 2.11929 5.11929 1 6.5 1H19.5C20.8807 1 22 2.11929 22 3.5V20.5C22 21.8807 20.8807 23 19.5 23H6.5C5.11929 23 4 21.8807 4 20.5V3.5Z"
        fill="#E9E9E9"
      />
      <path
        d="M3.6 3.5C3.6 2.00883 4.80883 0.8 6.3 0.8H19.7C21.1912 0.8 22.4 2.00883 22.4 3.5H21.6C21.6 2.22975 20.6598 1.2 19.5 1.2H6.5C5.3402 1.2 4.4 2.22975 4.4 3.5H3.6ZM22.4 20.5C22.4 21.9912 21.1912 23.2 19.7 23.2H6.3C4.80883 23.2 3.6 21.9912 3.6 20.5H4.4C4.4 21.7703 5.3402 22.8 6.5 22.8H19.5C20.6598 22.8 21.6 21.7703 21.6 20.5H22.4ZM6.3 23.2C4.80883 23.2 3.6 21.9912 3.6 20.5V3.5C3.6 2.00883 4.80883 0.8 6.3 0.8L6.5 1.2C5.3402 1.2 4.4 2.22975 4.4 3.5V20.5C4.4 21.7703 5.3402 22.8 6.5 22.8L6.3 23.2ZM19.7 0.8C21.1912 0.8 22.4 2.00883 22.4 3.5V20.5C22.4 21.9912 21.1912 23.2 19.7 23.2L19.5 22.8C20.6598 22.8 21.6 21.7703 21.6 20.5V3.5C21.6 2.22975 20.6598 1.2 19.5 1.2L19.7 0.8Z"
        fill="white"
        mask="url(#path-1-inside-1_3118_6833)"
      />
      <rect x="6.94" y="1.94" width="12.12" height="20.12" rx="2.56" fill="white" stroke="white" strokeWidth="0.12" />
      <path
        d="M13.8897 20.1237C13.6073 19.8865 13.7956 19.7827 13.7799 19.679C13.5916 18.9378 12.9641 18.493 13.2936 18.2558C13.2263 18.0689 13.1477 17.8857 13.0583 17.7073C12.0857 17.1885 11.27 16.3287 11.1288 16.2101C10.9876 16.0915 10.6739 15.8543 10.6111 15.7209C10.5483 15.5875 9.24633 13.9864 8.97963 13.438L8.52473 13.3786C8.3208 12.5633 7.61488 11.8814 7.5992 11.0809C7.61986 10.7088 7.68834 10.3404 7.80314 9.98389C7.89423 9.85092 8.0052 9.73109 8.13256 9.6281V9.40575C7.1286 9.43541 7.41095 9.33165 7.23842 8.96101C7.06586 8.59039 7.20704 8.6497 7.2541 8.47182C7.39527 7.95296 7.83452 7.13764 8.02275 6.76702C8.211 6.3964 8.27374 6.12958 8.27374 6.12958C9.02674 4.69162 9.30908 4.79537 10.2033 4.70643L10.2503 4.64715C11.1445 4.61751 11.0033 4.54339 11.1288 3.72805C11.0033 3.78734 10.9562 3.47603 10.9562 3.47603C10.8778 3.01647 11.0974 3.10541 11.1915 3.09059C11.2072 2.33454 11.3013 2.08253 11.8818 1.875H6.91332C5.92564 1.875 5.125 2.63161 5.125 3.56497V20.435C5.125 21.3683 5.92564 22.125 6.91332 22.125H14.125C13.5132 21.8137 13.874 21.2356 13.8897 20.1237Z"
        fill="#00438C"
      />
      <path
        d="M19.1495 1.875H12.5352C12.7429 1.93013 12.9205 2.06319 13.0304 2.24589C13.2404 2.26072 13.4955 2.73544 13.1054 3.41787C13.2854 3.53655 13.1504 3.69973 13.0154 3.89259C12.8803 4.08545 12.9404 4.07062 12.8353 4.05577C12.6853 4.36731 12.5052 4.66402 12.3702 4.67887C12.3106 4.74515 12.3044 4.8431 12.3552 4.91623C12.5557 4.9971 12.7428 5.10711 12.9104 5.2426V5.31678C13.0604 5.40578 13.1354 5.47996 13.3004 5.56897C13.7056 5.82115 14.2157 6.28105 14.1557 7.85357C14.2757 8.19479 14.3207 8.86237 14.4258 9.0849C14.5308 9.30743 14.7859 9.78214 14.8459 10.1679C14.8459 10.1679 14.9059 10.7464 14.9809 10.8206H15.0259C15.371 10.8948 15.311 10.9393 15.341 10.9986L15.431 11.0728C15.5211 11.1173 15.6711 11.1618 15.6711 11.325L15.7461 11.4437C15.7919 11.5146 15.8321 11.5891 15.8662 11.6662C15.9863 12.0022 15.9863 12.3687 15.8662 12.7046V12.7492C15.7337 13.074 15.4892 13.342 15.176 13.5058H15.146L15.101 13.5355C14.8991 13.63 14.6791 13.6806 14.4558 13.6838C13.6179 13.5738 13.0289 12.8131 13.1401 11.9847C13.2132 11.4402 13.5789 10.9776 14.0957 10.7761C13.9156 10.3459 13.5555 9.64867 13.4505 9.42614C13.3455 9.2036 13.0904 7.76459 13.0454 7.4827C13.0004 7.20086 12.3852 7.85359 12.3852 7.88327C12.3852 7.91294 11.9051 9.07007 11.8901 9.12943C11.8792 9.19312 11.8742 9.25769 11.8751 9.32228C11.8751 9.32228 12.1002 9.35194 12.2202 9.72283C12.3402 10.0937 12.7303 11.4734 12.7303 11.4734L12.6253 11.5772C13.1054 13.1645 12.9404 13.847 13.1504 14.3217C13.3605 14.7964 13.4955 14.8854 13.7206 15.4492C13.9456 16.0129 14.0657 17.4371 14.1407 17.4964C14.3958 17.8228 14.5458 18.075 14.5458 18.253C14.5458 18.431 14.3207 18.98 14.4108 19.3063C14.5008 19.6327 14.4858 19.9146 14.5758 19.9887C14.6658 20.0629 14.6508 20.1519 14.6208 20.2113C14.6041 20.2383 14.5938 20.2687 14.5908 20.3003C14.7258 20.8046 15.191 21.6503 14.4558 22.0953L14.4108 22.125H19.1795C20.1125 22.1169 20.8669 21.3711 20.875 20.4487V3.5662C20.8667 2.62978 20.0967 1.87496 19.1495 1.875Z"
        fill="#DA1A32"
      />
    </svg>
  );
};
