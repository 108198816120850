export const ClickIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
      <path
        d="M14.25 13.75L12.4166 18.3334L8.74996 8.25002L18.8333 11.9167L14.25 13.75ZM14.25 13.75L18.8333 18.3334M7.08918 2.052L7.80093 4.7083M5.20818 7.30102L2.55188 6.58926M13.2872 3.71279L11.3427 5.65733M6.15729 10.8427L4.21275 12.7872"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
